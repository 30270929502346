<template>

  <div>
    <b-row>
      <!-- solid color -->
      <b-col
        v-for="(data,index) in solidColor"
        :key="index"
        md="6"
        xl="3"
      >
        <b-link :to="{ name: data.routeToGo }">
          <b-card
            class="cardPointer d-flex justify-content-center align-items-center flex-column"
            :bg-variant="data.bg"
            text-variant="white"
          >
            <h3 class="text-white">
              {{ data.title }}
            </h3>
            <!-- <b-card-text>
              {{ data.text }}
            </b-card-text>
            <feather-icon
              icon="ActivityIcon"
            /> -->
          </b-card>
        </b-link>

      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BLink,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    // BCardTitle,
    BLink,
  },
  data() {
    return {
      solidColor2: [
        {
          bg: 'primary',
          title: 'Aggiungi Paziente',
          text: 'Inserisci un nuovo paziente',
          routeToGo: 'general-organization',
        },
        {
          bg: 'primary',
          title: 'Panifica Attività',
          text: 'Inserisci un attività da svolgere o da fare svolgere',
          routeToGo: 'general-users',
        },
        {
          bg: 'primary',
          title: 'Panifica Evento',
          text: 'Inserisci un Evento',
          routeToGo: 'general-users',
        },
        {
          bg: 'primary',
          title: 'Gestisci Telefonate',
          text: 'Memorizza tutte le chiamate ricevute',
          routeToGo: 'general-users',
        },
        {
          bg: 'primary',
          title: 'Gestisci Accoglienza',
          text: 'Registra tutte le persone ricevute',
          routeToGo: 'general-users',
        },
        {
          bg: 'primary',
          title: 'Gestisci Prenotazione',
          text: 'Memorizza tutte le richieste di prenotazioni giornaliere',
          routeToGo: 'general-users',
        },
        {
          bg: 'primary',
          title: 'Gestisci Appuntamento',
          text: 'Memorizza tutti gli appuntamenti non giornalieri',
          routeToGo: 'general-users',
        },
        {
          bg: 'secondary',
          title: 'Aggiungi Cliente',
          text: 'Registra un nuovo cliente',
          routeToGo: 'list-customers',
        },
        {
          bg: 'success',
          title: 'Visita Paziente',
          text: 'Registra un nuova visita',
          routeToGo: 'general-warehouse',
        },
        {
          bg: 'success',
          title: 'Report Visita',
          text: 'Crea un report',
          routeToGo: 'general-warehouse',
        },
        {
          bg: 'success',
          title: 'Aggiungi Diagnosi',
          text: 'Crea una nuova diagnosi',
          routeToGo: 'general-warehouse',
        },
        {
          bg: 'success',
          title: 'Prescrivi Terapia',
          text: 'Crea una terapia per un paziente',
          routeToGo: 'general-warehouse',
        },
        {
          bg: 'success',
          title: 'Prescrivi una nuova indagine',
          text: 'Crea una nuova indagine',
          routeToGo: 'general-warehouse',
        },
        {
          bg: 'danger',
          title: ' Gestione Vaccinazioni',
          text: 'Gestisci le vaccinazioni',
          routeToGo: 'general-vaccinations',
        },
        {
          bg: 'info',
          title: 'Analisi',
          text: 'Gestisci Analisi',
          routeToGo: 'general-analysis',
        },
        {
          bg: 'success',
          title: 'Fatturazione',
          text: 'Gestisci le fatturazione',
          routeToGo: 'general-business',
        },
        {
          bg: 'warning',
          title: 'Ricoveri',
          text: 'Gestisci Ricoveri',
          routeToGo: 'general-shelter',
        },
        {
          bg: 'warning',
          title: 'Esegui Inventario Farmaci',
          text: 'Esegui inventario farmaci',
          routeToGo: 'general-shelter',
        },
        {
          bg: 'warning',
          title: 'Esegui Inventario materiali di consumo',
          text: 'Esegui inventario  materiali di consumo',
          routeToGo: 'general-shelter',
        },
        {
          bg: 'warning',
          title: 'Inserisci Nuovo ordine',
          text: 'Esegui Nuovo ordine',
          routeToGo: 'general-shelter',
        },
        {
          bg: 'warning',
          title: 'Inserisci Ricovero',
          text: 'Esegui Ricovero',
          routeToGo: 'general-shelter',
        },
        {
          bg: 'warning',
          title: 'Monitorizza Ricovero',
          text: 'Monitorizza Ricovero',
          routeToGo: 'general-shelter',
        },
      ],
      solidColor: [
        /* {
          bg: 'primary',
          title: 'Aggiungi Interazione',
          text: 'Registra un interazione',
          routeToGo: 'engagment',
        },
        {
          bg: 'primary',
          title: 'Aggiungi Interazione1',
          text: 'Registra un interazione1',
          routeToGo: 'engagment1',
        },
        {
          bg: 'primary',
          title: 'Aggiungi Paziente',
          text: 'Inserisci un nuovo paziente',
          routeToGo: 'general-organization',
        },
        {
          bg: 'primary',
          title: 'Panifica Attività',
          text: 'Inserisci un attività da svolgere o da fare svolgere',
          routeToGo: 'general-users',
        },
        {
          bg: 'secondary',
          title: 'Carica Prezzi',
          text: 'Inserisci un nuovo paziente',
          routeToGo: 'load-prices-products',
        },
        {
          bg: 'secondary',
          title: 'Carica Prezzi',
          text: 'vue-awesome-swiper',
          routeToGo: 'load-prices-products2',
        }, */
        {
          bg: 'secondary',
          title: 'DDT Multipli',
          text: 'vue-awesome-swiper',
          routeToGo: 'multiple-ddt',
        },
        {
          bg: 'secondary',
          title: 'Prezzo Plu',
          text: 'vue-awesome-swiper',
          routeToGo: 'load-price-plu',
        },
        {
          bg: 'info',
          title: 'Stato Invio Bilance',
          text: 'vue-awesome-swiper',
          routeToGo: 'sending-status',
        },
      ],
    }
  },
}
</script>
<style scoped>
  .cardPointer{
    position: relative;
    top: 0;
    transition: top ease 0.5s;
    min-height: 190px;
  }
  .cardPointer:hover{
    cursor: pointer;
    top: -10px;

  }
</style>
